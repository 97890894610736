<template>
  <div class="home">
    <HeaderComponent />
    <main>
      <section id="content">
        <h1>Política de Privacidade</h1>
        <p>
          A política de privacidade explica quais dados pessoais coletamos e de
          que forma eles são usados. O Site coleta estas informações para
          analisar e proporcionar melhores experiências com os nossos serviços.
          Se você não concordar com a Política de Privacidade, não deve acessar
          ou usar o Site.
        </p>
        <div id="ezoic-pub-ad-placeholder-102"></div>
        <br />
        <h2>Informações coletadas</h2>
        <p>
          O Site pode coletar informações pessoais, como seu endereço de email,
          quando você usa o formulário de contato ou se inscreve em nossos
          serviços. Além disso, coletamos informações sobre seu uso de nossos
          serviços, incluindo seu endereço IP, navegador da Web e sistema
          operacional, e identificamos o número de visitantes e entendemos como
          eles usam o Site.
        </p>
        <h2>Uso das informações</h2>
        <p>
          Usamos suas informações pessoais para fornecer e melhorar nossos
          serviços, incluindo personalização de conteúdo e análise de dados.
          Nossa equipe pode entrar em contato para enviar atualizações sobre
          nossos serviços ou responder a perguntas e comentários.
        </p>
        <h2>Compartilhamento de informações</h2>
        <p>
          Não vendemos ou compartilhamos suas informações pessoais com
          terceiros, exceto quando necessário para fornecer nossos serviços ou
          conforme exigido por lei.
        </p>
        <h2>Segurança</h2>
        <p>
          Tomamos medidas razoáveis para proteger suas informações pessoais
          contra perda, roubo ou uso não autorizado. No entanto, não podemos
          garantir que suas informações estejam completamente seguras.
        </p>
        <h2>Atualização de informações</h2>
        <p>
          Para solicitar uma atualização ou correção de uma URL encurtada que
          você criou, acesse o formulário de contato e informe a URL longa e a
          URL curta que deseja alterar.
        </p>
        <h2>Direitos de privacidade</h2>
        <p>
          É possível que o Curtinho faça o envio de mensagens para divulgar um
          produto ou serviço e você pode optar por não receber essas mensagens
          de marketing se decadastrando da nossa lista.
        </p>
        <div id="ezoic-pub-ad-placeholder-106"></div>
        <br />
        <h2>Cookies e rede de publicidade</h2>
        <p>
          Podemos usar empresas de publicidade de terceiros para exibir anúncios
          quando você visita nosso Site. Estas empresas podem utilizar cookies
          que são pequenos arquivos de texto colocados em seu dispositivo e
          tecnologias semelhantes para coletar informações com a finalidade de
          exibir anúncios relacionados a produtos e serviços de seu interesse.
        </p>
        <p>
          O Curtinho pode exibir anúncios servidos por empresas de publicidade
          que usam cookies para identificar as preferências do usuário e os
          hábitos de navegação. Os usuários podem obter mais informações sobre
          esses cookies e privacidade nos sites dessas empresas.
        </p>
        <p>
          Os navegadores da web aceitam cookies automaticamente, mas se preferir
          você pode modificar a configuração de seu navegador para recusar
          cookies. No entanto, isso pode afetar a forma como você é capaz de
          interagir com o nosso site e com outros sites na Internet.
        </p>
        <h2>Alterações na política de privacidade</h2>
        <p>
          Reservamos o direito de modificar esta política a qualquer momento.
          Qualquer alteração será publicada nesta página e entrará em vigor
          imediatamente.
        </p>
        <div id="ezoic-pub-ad-placeholder-108"></div>
        <br />
        <h2>Outras informações importantes</h2>
        <p>
          Se houver alguma dúvida sobre privacidade ou precisar de alguma
          informação adicional, entre em contato com a nossa equipe.
        </p>
      </section>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>
