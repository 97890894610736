<template>
  <div class="home">
    <HeaderComponent />
    <main>
      <section id="content">
        <h1>Sua URL encurtada</h1>
        <p>
          Copie o link curto e compartilhe em mensagens, textos, publicações,
          sites e outros locais.
        </p>
      </section>

      <br />

      <section id="urlbox">
        <br /><br />
        <span
          class="universal-js-insert"
          style="display: block; padding: 30px 0px; text-align: center"
        >
          <div
            id="humix-vid-ezAutoMatch"
            props="autoplay=1"
            style="max-width: 640px !important; margin: 0 auto"
            float=""
            data-ez-pos="0"
            class="humix-vid-embed-container magic-embed-float"
          ></div>
        </span>
        <div id="formurl" class="">
          <input id="shortenurl" type="text" v-model="link" />
          <div id="formbutton">
            <input
              type="button"
              data-clipboard-target="#shortenurl"
              class="copy"
              value="Copiar URL"
              @click="copiar"
            />
          </div>
        </div>
        <div id="formurl" class="">
          <div id="balloon" v-if="copy">URL Copiada</div>
        </div>
        <div id="formurl" class="">
          <p class="boxtextleft">
            URL Longa:
            <a :href="link_long" target="_blank">{{ link_long }}</a
            ><br /><br />
            <a href="/contador" class="colorbuttonsmall"
              >Total de cliques da sua URL curta</a
            ><br />
            <a href="/" class="colorbuttonsmall">Encurtar outra URL</a
            ><br /><br />
            <span class="textmedium"
              >* URLs curtas que não tem pelo menos um clique por mês são
              desativadas</span
            >
          </p>
        </div>
      </section>

      <br />
    </main>
    <FooterComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
  },

  data() {
    return {
      link_long: "",
      link: "",
      copy: false,
    };
  },
  methods: {
    copiar() {
      // Cria um elemento de área de transferência temporário
      const el = document.createElement("textarea");
      el.value = this.link;
      document.body.appendChild(el);

      // Seleciona e copia o texto no elemento de área de transferência
      el.select();
      document.execCommand("copy");

      // Remove o elemento de área de transferência temporário
      document.body.removeChild(el);

      // Mensagem opcional para indicar que o texto foi copiado
      this.copy = true;
      setTimeout(() => {
        this.copy = false;
      }, 4000);
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.link = `flyn.one/${this.$route.query?.token}`;
      this.link_long = `${this.$route.query?.urllong}`;
    });
  },
};
</script>

<style scoped>
#ismany-info {
  display: none;
  color: #555; /* Cor do texto informativo */
  font-style: italic; /* Estilo do texto informativo */
}

#ismany:checked + label + p {
  display: block;
}
</style>
