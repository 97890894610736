<template>
  <div class="home">
    <HeaderComponent />
    <main>
      <section id="content">
        <h1>Contador de Cliques da URL</h1>
        <p>
          Digite a URL curta abaixo para acompanhar quantos cliques ela recebeu.
        </p>
      </section>
      <div id="ezoic-pub-ad-placeholder-102"></div>
      <br />
      <section id="urlbox">
        <br /><br />
        <form>
          <div id="formurl">
            <input
              type="text"
              placeholder="Digite aqui sua URL encurtada"
              v-model="token"
              @click="
                error_link = false;
                show_result = false;
              "
            />
            <div id="formbutton">
              <input type="button" value="Contar Cliques" @click="count" />
            </div>
          </div>
        </form>
        <span v-if="error_link" style="color: red">Preencha a URL</span><br />

        <p class="" v-if="!show_result">Exemplo: curtinho.com.br/DeFgH</p>
        <div v-if="show_result">
          <h1>Total de Cliques da URL: {{ count_clicks }}</h1>
        </div>
      </section>
      <section id="content">
        <p>
          * Acompanhe o total de acessos da URL encurtada em tempo real, não
          precisa se registrar.
        </p>
      </section>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import axios from "axios";
import { url_base } from "../../env";
export default {
  name: "ContadorView",
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {
      token: "",
      error_link: false,
      count_clicks: 0,
      show_result: false,
    };
  },
  methods: {
    async count() {
      if (this.token) {
        if (this.token.split("/")[this.token.split("/").length - 1]) {
          let token = this.token.split("/")[this.token.split("/").length - 1];
          let count = await axios.get(`${url_base}/count/${token}`);
          this.count_clicks = count.data.count;
          this.show_result = true;
        } else {
          this.show_result = false;
        }
      } else {
        this.error_link = true;
      }
    },
  },
};
</script>
