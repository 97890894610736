import { createRouter, createWebHistory } from "vue-router";
import ContadorView from "../views/ContadorView.vue";
import HomeView from "../views/HomeView.vue";
import PrivacidadeView from "../views/PrivacidadeView.vue";
import TermosView from "../views/TermosView.vue";
import URLEncurtada from "../views/URLEncurtada.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => {
      return import(/* webpackChunkName: "about" */ "../views/HomeView.vue");
    },
  },
  {
    path: "/:id",
    name: "token",
    component: HomeView,
  },
  {
    path: "/contador",
    name: "contador",
    component: ContadorView,
  },
  {
    path: "/termos",
    name: "termos",
    component: TermosView,
  },
  {
    path: "/privacidade",
    name: "privacidade",
    component: PrivacidadeView,
  },
  {
    path: "/shorturl",
    name: "URL Encurtada",
    component: URLEncurtada,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
