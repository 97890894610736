<template>
  <div>
    <footer>
      <div id="footermenu">
        <nav id="footermenu">
          <ul>
            <li>
              <a href="/termos">Termos&nbsp;de&nbsp;Serviço</a>
            </li>
            <li><a href="/privacidade">Privacidade</a></li>
          </ul>
        </nav>
        <div>
          © {{ year }} curtinho.com.br - Ferramenta para encurtar um link longo.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {
    msg: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
