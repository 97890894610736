<template>
  <router-view />
  <!-- <a
    id="whatsapp-button"
    href="https://api.whatsapp.com/send?phone=11995540438"
    target="_blank"
  >
    <img
      src="https://seeklogo.com/images/W/whatsapp-icon-logo-6E793ACECD-seeklogo.com.png"
      width="20%"
    />
  </a> -->
</template>

<style lang="scss">
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

body {
  margin: 0;
  background: #f9f9f9;
  font: 16px oxygen, arial;
}

h1 {
  margin: 0;
  font: bold 36px "maven pro", arial;
  color: #1b1b3a;
  letter-spacing: -1px;
}

h2 {
  margin: 0;
  font: bold 26px "maven pro", arial;
  color: #1b1b3a;
  letter-spacing: -1px;
}

h3 {
  margin: 0;
  font: bold 22px "maven pro", arial;
  color: #1b1b3a;
  letter-spacing: -1px;
}

p {
  font: 16px oxygen, arial;
  color: #000;
  line-height: 1.5;
  text-align: left;
  padding: 0 0 0 2px;
}

a {
  color: #006cff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

b {
  opacity: 0.8;
}

ul {
  font: 16px oxygen, arial;
  color: #000;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  font: 16px oxygen, arial;
  color: #000;
  line-height: 1.5;
  text-align: left;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

br {
  clear: both;
}

.h5 {
  clear: both;
  display: block;
  content: "";
  height: 5px;
}

.h10 {
  clear: both;
  display: block;
  content: "";
  height: 10px;
}

.h15 {
  clear: both;
  display: block;
  content: "";
  height: 15px;
}

.h20 {
  clear: both;
  display: block;
  content: "";
  height: 20px;
}

.colorred {
  color: red;
}

.mw450 {
  max-width: 450px !important;
}

.mw450dblock {
  max-width: 450px !important;
  display: block !important;
}

.w100mw300 {
  width: 100%;
  max-width: 300px;
}

.w100mw300h80 {
  width: 100%;
  max-width: 300px;
  height: 80px;
}

.w100mw40 {
  width: 100%;
  max-width: 40px;
}

.p050 {
  padding: 0 50px;
}

input[type="text"],
textarea {
  vertical-align: middle;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
  color: #444;
}

input[type="submit"] {
  font: 16px arial, tahoma;
  height: 36px;
  vertical-align: middle;
}

.logo {
  font: bold 40px arial, tahoma;
  color: #222;
  word-wrap: break-word;
}

.textsmall {
  font-size: 13px;
  color: #999;
}

.textsmall:hover {
  text-decoration: none;
}

.textmedium {
  font-size: 13px;
  word-wrap: normal;
  word-break: normal;
}

.textbig {
  font-size: 15px;
}

.alignleft {
  text-align: left;
}

.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

.alignmiddle {
  vertical-align: middle;
}

.colorbutton {
  display: inline-block;
  background: #f24236;
  font: bold 16px oxygen, arial;
  color: #fff;
  padding: 16px 26px;
  border: 0;
  border-radius: 3px;
  text-decoration: none;
  margin: 0 0 5px 0;
}

.colorbutton:hover {
  text-decoration: none;
}

.colorbuttonsmall {
  display: inline-block;
  background-color: #f24236;
  font: normal 16px oxygen, arial;
  color: #fff;
  padding: 8px 16px;
  border: 0;
  border-radius: 3px;
  text-decoration: none;
  margin: 0 0 5px 0;
  height: 38px;
}

.colorbuttonsmal:hover {
  text-decoration: none;
}

.list {
  line-height: 1.5;
  // background: url(/img/icone-lista.svg) no-repeat left;
  padding: 0 0 0 35px;
}

header {
  text-align: center;
  font: 16px "maven pro", arial, tahoma;
  color: #eee;
  width: 100%;
  padding: 0 0;
  line-height: 1.5;
}

header #headerbox {
  padding: 8px 10px;
  background: #1b1b3a;
  border-bottom: 4px solid #f24236;
}

header #top {
  background: #fff;
  box-shadow: 0 1px 4px #ccc;
}

header #logo {
  padding: 25px 20px 20px;
}

header a.logo {
  font: bold 38px arial, tahoma;
  color: #222;
  text-transform: uppercase;
  vertical-align: middle;
}

#cssmenu {
  margin: auto;
  text-align: center;
}

#cssmenu ul {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 7px 30px;
  min-height: 18px;
}

#cssmenu ul:after {
  clear: both;
  content: "";
  display: table;
}

#cssmenu ul li:first-child {
  padding-bottom: 20px;
}

#cssmenu ul li {
  float: left;
  border-right: 1px solid #001121;
  height: 18px;
}

#cssmenu ul li:last-child {
  background: 0 0;
  border-right: 0;
}

#cssmenu ul li.mobile {
  display: none;
}

#cssmenu ul li.mobile a {
  font: bold 16px "maven pro", arial, tahoma;
  padding: 7px 10px;
  letter-spacing: 0;
}

#cssmenu div.mobilesearch {
  display: none;
  right: 0;
  background: #fff;
  width: 240px;
  margin: 0 20px 0 0;
  position: absolute;
}

#cssmenu ul li a {
  font: 16px "maven pro", arial, tahoma;
  color: #fff;
  display: block;
  padding: 0 13px 0;
  text-decoration: none;
  overflow: hidden;
  letter-spacing: 0;
}

#cssmenu ul li a.linkcat {
  font: 16px "maven pro", arial, tahoma;
  color: #fff;
  display: block;
  padding: 11px 20px 9px;
  text-decoration: none;
  overflow: hidden;
}

#cssmenu ul li a:hover {
  text-decoration: underline;
}

main {
  display: block;
  margin: 0 auto 0 auto;
  padding: 0 20px;
  max-width: 1000px;
}

section#urlbox {
  margin: 0 auto 20px auto;
  max-width: 758px;
  box-shadow: 0 1px 4px #ccc;
  border-radius: 6px;
  padding: 10px 30px 5px;
  background: #fff;
  text-align: center;
}

section#urlbox h1 {
  margin: 10px auto 30px auto;
}

section .boxtextcenterdesc {
  margin: 0 auto 20px auto;
  text-align: center;
  max-width: 620px;
}

section .boxtextcenter {
  margin: 10px auto 30px;
  text-align: center;
  max-width: 620px;
}

section .boxtextleft {
  max-width: 450px;
  word-wrap: break-word;
  word-break: break-all;
}

section#content {
  max-width: 720px;
  margin: 30px auto 0;
  padding: 0 0 20px 0;
}

section .squarebox {
  display: inline-block;
  background: #fff;
  padding: 20px 35px;
  border-radius: 3px;
  font: bold 42px "maven pro", arial;
  color: #444;
}

.squareboxbig {
  display: inline-block;
  background: #fff;
  padding: 20px 40px 20px 35px;
  border-radius: 3px;
  font: bold 44px "source sans pro", arial;
  color: #4b4b4b;
}

.squareboxtext {
  transform: scale(1.1, 1);
}

.squareboxurl {
  margin: 18px 0 0 0;
}

.squareboxurl a {
  font: 18px oxygen, arial;
  padding: 10px 20px 12px;
  background: #fff;
  border-radius: 4px;
}

#formurl {
  display: table;
  max-width: 600px;
  margin: 0 auto;
}

#formurl input[type="text"] {
  display: table-cell;
  width: 100%;
  height: 56px;
  padding: 10px 16px;
  font: 16px oxygen, arial;
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-right: 0;
  border-radius: 3px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  box-sizing: border-box;
}

#formurl #formbutton {
  display: table-cell;
  width: 1%;
  box-sizing: border-box;
  vertical-align: middle;
}

#formurl input[type="button"],
#formurl input[type="submit"] {
  height: 56px;
  padding: 10px 16px;
  font: bold 16px oxygen, arial;
  color: #fff;
  background-color: #f24236;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
  -webkit-appearance: button;
  margin: 0;
}

#balloon {
  background: #1b1b3a;
  font: 13px "maven pro", arial;
  color: #fff;
  padding: 8px;
  text-align: center;
  border-radius: 3px;
  white-space: nowrap;
  float: right;
  margin: 4px 0 4px 4px;
}

section#emailbox {
  margin: 0 auto 20px auto;
  max-width: 758px;
  box-shadow: 0 1px 4px #ccc;
  border-radius: 6px;
  padding: 10px 30px 5px;
  background: #fff;
  text-align: center;
}

section#emailbox h2 {
  margin: 15px auto 20px auto;
}

#formemail {
  display: table;
  max-width: 400px;
  margin: 0 auto;
}

#formemail input[type="email"] {
  display: table-cell;
  width: 100%;
  height: 56px;
  padding: 10px 16px;
  font: 16px oxygen, arial;
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-right: 0;
  border-radius: 3px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  box-sizing: border-box;
}

#formemail #formbutton {
  display: table-cell;
  width: 1%;
  box-sizing: border-box;
  vertical-align: middle;
}

#formbutton button[type="button"] {
  height: 56px;
  padding: 10px 16px;
  font: bold 16px oxygen, arial;
  color: #fff;
  background-color: #f24236;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
  -webkit-appearance: button;
  margin: 0;
}

#formemail input[type="button"],
#formemail input[type="submit"] {
  height: 56px;
  padding: 10px 16px;
  font: bold 16px oxygen, arial;
  color: #fff;
  background-color: #f24236;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
  -webkit-appearance: button;
  margin: 0;
}

footer {
  text-align: center;
  font: 16px oxygen, arial;
  color: #eee;
  background: #1b1b3a;
  width: 100%;
  border-top: 4px solid #f24236;
  padding: 20px 0 121px;
  line-height: 1.5;
  margin: 40px 0 0 0;
}

footer #footerbox {
  padding: 0 20px;
}

footer ul {
  display: inline-block;
  list-style: none;
  margin: 10px 0 0 0;
}

footer ul li {
  display: inline;
  padding: 0 12px;
  border-right: 1px solid #001121;
  height: 18px;
}

footer ul li:last-child {
  border: none;
}

footer ul li a {
  font: 16px "maven pro", arial;
  color: #fff;
  letter-spacing: 0;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
  margin-bottom: 200px;
}

footer {
  position: absolute;
  bottom: 0;
  height: 40px;
}

@media all and (max-width: 499px) {
  main {
    padding: 0 10px;
  }

  header a.logo {
    font: bold 29px arial, tahoma;
    vertical-align: middle;
  }

  section .boxtextleft {
    padding: 0;
    word-wrap: break-word;
  }
}

#formbox {
  max-width: 400px;
  margin: 20px 0;
  padding: 10px 40px 10px 20px;
  font: 16px oxygen, arial;
  color: #222;
  background-color: #fff;
  text-align: left;
  border-radius: 6px;
  box-shadow: 0 1px 4px #ccc;
}

input[type="email"],
input[type="text"],
textarea {
  font: 16px "maven pro", arial;
  padding: 8px;
  vertical-align: middle;
  border-radius: 1px;
  border: 1px solid #b6b6b6;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 -1px 1px #fff, 0 1px 0 #fff;
}

input[name="submit"] {
  height: 35px;
  padding: 2px 12px 3px 12px;
  font: normal 15px oxygen, arial;
  color: #fff;
  background-color: #f24236;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
  border-radius: 3px;
}

#button {
  text-align: right;
}

.formtext {
  font: 16px oxygen, arial;
  color: #222;
  line-height: 1.5;
}

.formemail {
  display: none;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#row:after {
  display: table;
  content: "";
  clear: both;
}

#column {
  float: left;
  width: 33.33%;
  min-height: 210px;
  padding: 0 10px;
  text-align: center;
}

.socialnetworkbox {
  display: block;
  margin: 2px 2px 2px 0;
}

.snb {
  padding: 8px 10px 9px 10px;
  margin: 0 1px 0 0;
  font: 14px lato, arial;
  color: #fff;
  text-align: center;
  background-color: #3b5998;
  cursor: pointer;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 4px;
}

.snb:hover {
  text-decoration: none;
}

.snbfacebook {
  background: #3b5998;
}

.snbtwitter {
  background: #55acee;
}

.snbpinterest {
  background: #d4374f;
}

.snbtumblr {
  background: #243a4f;
}

.snbwhatsapp {
  background: #16b75b;
}

.snbtelegram {
  background: #3390ec;
}

.snbreddit {
  background: #ff4500;
}

.snblinkedin {
  background: #0a66c2;
}

.emain {
  margin: 50px auto;
}

.esection {
  margin: 0 auto 20px auto;
  max-width: 500px;
  box-shadow: 0 1px 4px #ccc;
  border-radius: 6px;
  padding: 50px 30px 30px;
  background: #fff;
  text-align: center;
}

.eh1 {
  margin: 0 0 10px 0;
  font: bold 100px oxygen, arial;
  color: #444;
  line-height: 1;
  text-align: center;
  letter-spacing: -4px;
}

.eh2 {
  margin: 0 0 -10px 0;
  font: bold 28px "maven pro", arial;
  color: #444;
  letter-spacing: -1px;
  text-align: center;
}

.ebox {
  margin: 30px auto 0;
  font: 17px oxygen, arial;
  color: #000;
  text-align: center;
  line-height: 1.5;
  max-width: 440px;
}

.ebutton {
  margin: 40px auto;
  text-align: center;
}

.colorbuttonmedium {
  background: #f24236;
  font: 16px oxygen, arial;
  color: #fff;
  padding: 12px 20px;
  border-radius: 3px;
}

.colorbuttonmedium:hover {
  text-decoration: none;
}

#adbox {
  height: auto;
  margin: 10px auto 15px;
  text-align: center;
}

#adbox160x600 {
  max-width: 160px;
  height: auto;
  margin: 20px auto;
  text-align: center;
}

#adbox300x250 {
  max-width: 300px;
  height: auto;
  margin: 20px auto;
  text-align: center;
}

#adbox300x600 {
  max-width: 300px;
  height: auto;
  margin: 20px auto;
  text-align: center;
}

#adbox320x50 {
  max-width: 320px;
  height: auto;
  margin: 20px auto;
  text-align: center;
}

#adbox336x280 {
  max-width: 336px;
  height: auto;
  margin: 20px auto;
  text-align: center;
}

#adbox468x60 {
  max-width: 468px;
  height: auto;
  margin: 20px auto;
  text-align: center;
}

#adbox728x90 {
  max-width: 728px;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

#adboxyoutube {
  width: 300px;
  height: 250px;
  margin: 30px auto 20px auto;
  text-align: center;
  background: #eee;
}

#adboximg {
  max-width: 100%;
  width: 500px;
  height: auto;
}

.clever-core-ads {
  max-width: 728px;
  max-height: 300px;
  overflow: hidden;
  text-align: center;
  margin: -8px auto 12px auto;
}

.inarticle {
  margin: 0 0 30px 0;
}

@media all and (max-width: 499px) {
  .ad {
    width: 320px;
    height: 100px;
  }
}

@media all and (min-width: 500px) and (max-width: 769px) {
  .ad {
    width: 468px;
    height: 60px;
  }
}

@media all and (min-width: 770px) and (max-width: 999px) {
  .ad {
    width: 728px;
    height: 90px;
  }
}

@media all and (min-width: 1000px) {
  .ad {
    width: 970px;
    height: 90px;
  }
}

@media all and (max-width: 1000px) {
  html {
    padding-bottom: 160px;
  }

  #cssmenu ul {
    display: list-item;
    max-height: 24px;
    overflow: hidden;
    margin: 0 7px 8px 7px;
  }

  #cssmenu.expand ul {
    max-height: 100%;
  }

  #cssmenu ul li {
    float: none;
    background: 0 0;
    border-right: none;
  }

  #cssmenu ul li:nth-child(n + 2) {
    margin: 0 10px;
    padding: 8px 0 28px;
    border-bottom: 1px solid #001121;
  }

  #cssmenu ul li:last-child {
    border: none;
  }

  #cssmenu ul li.mobile {
    display: block;
    margin: 0;
    width: 40px;
    border-radius: 3px;
    height: 35px;
  }

  #cssmenu ul li.mobile span:before {
    position: absolute;
    left: 28px;
    height: 3px;
    width: 20px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    content: "";
    margin-top: 3px;
  }

  #cssmenu ul li.mobile span:after {
    position: absolute;
    left: 28px;
    height: 11px;
    width: 20px;
    border-bottom: 3px solid #fff;
    content: "";
    margin-top: 4px;
  }

  footer {
    margin: 40px 0 0 0;
    padding: 20px 0 315px;
  }

  #footermenu ul {
    width: 100%;
    max-height: 24px;
    overflow: hidden;
    margin: 8px 0;
    padding: 0 7px;
  }

  #footermenu ul li {
    display: block;
    float: none;
    background: 0 0;
    border-right: none;
  }

  #footermenu ul li:nth-child(n + 1) {
    margin: 0;
    padding: 8px 14px 28px;
    border-bottom: 1px solid #001121;
    border-right: none;
  }

  #footermenu ul li:last-child {
    border: none;
  }
}

.cccontainer {
  opacity: 1;
}

.cccontainer {
  border-style: solid;
  border-width: 0;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  font: 15px arial;
  line-height: 1.5;
  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  overflow: hidden;
  position: fixed;
  transition: opacity 1s ease;
  z-index: 9999;
}

@media print {
  .cccontainer {
    display: none;
  }
}

.ccinvisible {
  opacity: 0;
}

.ccfloat {
  flex-direction: column;
  max-width: 300px;
  padding: 22px;
}

.ccbtn,
.ccprivacy {
  cursor: pointer;
}

.ccprivacy {
  display: inline-block;
  opacity: 0.85;
  text-decoration: underline;
}

.ccprivacy:hover {
  opacity: 1;
}

.ccbtn {
  display: block;
  font-size: 15px;
  font-weight: 700;
  padding: 8px 12px;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
}

.ccbtn:hover {
  text-decoration: none;
}

.ccright {
  right: 16px;
}

.ccbottom {
  bottom: 16px;
}

.ccfloat .ccmessage {
  display: block;
  margin-bottom: 16px;
}

.ccbtn {
  border-radius: 2px;
}

@media screen and (max-width: 499px) and (orientation: portrait),
  screen and (max-width: 769px) and (orientation: landscape) {
  .cctop {
    top: 0 !important;
  }

  .ccbottom {
    bottom: 0 !important;
  }

  .ccbanner,
  .ccfloat,
  .ccleft,
  .ccright {
    left: 0 !important;
    margin: 0 !important;
    right: 0 !important;
  }

  .ccbanner {
    align-items: unset;
    flex-direction: column;
  }

  .cccorners-round {
    border-radius: 0 !important;
  }

  .ccfloat {
    max-width: none;
  }

  .ccmessage {
    margin-bottom: 16px;
  }

  .ccbanner.ccpadding-small {
    padding: 8px;
  }

  .ccpadding-small .ccmessage {
    margin-bottom: 8px;
  }

  #column {
    float: left;
    width: 50%;
    min-height: 210px;
    padding: 0 10px;
    text-align: center;
  }
}

#whatsapp-button {
  position: fixed;
  bottom: 20px;

  padding: 10px 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
}
</style>
