<template>
  <div class="home">
    <div v-if="!is_loading">
      <HeaderComponent />
      <main>
        <section id="urlbox">
          <h1>Cole a URL aqui</h1>
          <div id="formurl">
            <input
              type="text"
              name=""
              placeholder="Cole o link aqui"
              v-model="link_to_short"
              @click="error_link = false"
            />

            <div id="formbutton">
              <button type="button" @click="createLink">Gerar URL</button>
            </div>
          </div>
          <span v-if="error_link" style="color: red"
            >Verifique o endereço da URL</span
          ><br />
          <input type="checkbox" id="ismany" v-model="is_not_many" />
          <label for="ismany">Acesso único?</label>
          <p id="ismany-info">
            Com esta opção selecionada,<br />seu link encurtado só será acessado
            apenas uma vez.
          </p>
          <p class="boxtextcenter">
            O Curtinho é uma ferramenta para simplificação de URLs,
            <br />permitindo a geração de links curtos.
          </p>
        </section>
        <div id="ezoic-pub-ad-placeholder-102"></div>
        <br />

        <section id="content">
          <h2>Um encurtador de URL simples e rápido!</h2>
          <p>
            O Curtinho é uma ferramenta que encurta links para
            <a href="https://www.instagram.com/" target="_blank">Instagram</a>,
            <a href="https://www.facebook.com/" target="_blank">Facebook</a>,
            <a href="https://www.youtube.com/" target="_blank">YouTube</a>,
            <a href="https://www.twitter.com/" target="_blank">Twitter</a>,
            <a href="https://www.linkedin.com/" target="_blank">Linked In</a>,
            <a href="https://www.pinterest.com/" target="_blank">Pinterest</a>,
            <a href="https://www.whatsapp.com/" target="_blank">WhatsApp</a>,
            <a href="https://www.tiktok.com/" target="_blank">TikTok</a>, blogs
            e sites. Cole a URL longa e clique no botão Encurtar URL. Na próxima
            página, copie a URL encurtada e compartilhe em sites, chat e emails.
            Depois é possível saber
            <a href="/contador">quantos cliques ela recebeu</a>.
          </p>
          <h2>Encurte, compartilhe e monitore</h2>
          <p>
            As URLs encurtadas oferecem versatilidade ao serem aplicadas em
            diferentes contextos, como publicações, blogs, fóruns, e-mails,
            mensagens instantâneas, entre outros. Aumente a eficácia de suas
            estratégias de negócios e projetos ao monitorar as estatísticas de
            acesso à sua URL por meio de um contador de cliques.
          </p>
        </section>
        <div id="ezoic-pub-ad-placeholder-106"></div>
        <br />
        <section id="content">
          <div id="box">
            <div id="row">
              <div id="column">
                <div class="icon">
                  <img src="../assets/icone-gratis.png" width="50%" />
                </div>
                <h3 class="aligncenter">Grátis</h3>
                <p class="aligncenter">
                  Crie quantos links encurtados quiser e compartilhe
                </p>
              </div>
              <div id="column">
                <div class="icon">
                  <img src="../assets/icone-curto.png" width="50%" />
                </div>
                <h3 class="aligncenter">Curto</h3>
                <p class="aligncenter">
                  Encurte links de qualquer tamanho com o Curtinho
                </p>
              </div>
              <div id="column">
                <div class="icon">
                  <img src="../assets/icone-seguro.png" width="50%" />
                </div>
                <h3 class="aligncenter">Seguro</h3>
                <p class="aligncenter">
                  Usamos protocolo https com criptografia de dados nas URLs
                </p>
              </div>
              <div id="column">
                <div class="icon">
                  <img src="../assets/icone-estatisticas.png" width="50%" />
                </div>
                <h3 class="aligncenter">Estatísticas</h3>
                <p class="aligncenter">
                  Verifique o número de cliques da sua URL encurtada
                </p>
              </div>
              <div id="column">
                <div class="icon">
                  <img src="../assets/icone-dispositivos.png" width="50%" />
                </div>
                <h3 class="aligncenter">Responsivo</h3>
                <p class="aligncenter">
                  Compatível com desktop, tablets e dispositivos móveis
                </p>
              </div>
              <div id="column">
                <div class="icon">
                  <img src="../assets/icone-confiavel.png" width="50%" />
                </div>
                <h3 class="aligncenter">Confiável</h3>
                <p class="aligncenter">
                  Excluímos links que tentam disseminar spam e malware
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
    <div class="classic" v-if="is_loading"><span class="loader"></span></div>
  </div>
</template>

<script>
// @ is an alias to /src
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import axios from "axios";
import { url_base } from "../../env";
export default {
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
  },
  methods: {
    async createLink() {
      if (this.link_to_short) {
        if (this.validarURL(this.link_to_short)) {
          let token = await axios.post(`${url_base}/short`, {
            url: this.link_to_short,
            data: {},
            is_not_many: this.is_not_many,
          });
          if (token.data?.token) {
            this.$router.push(
              `/shorturl?token=${token.data.token}&urllong=${this.link_to_short}`
            );
          }
        } else {
          this.error_link = true;
        }
      } else {
        this.error_link = true;
      }
    },
    validarURL(url) {
      // Expressão regular para verificar se a string é uma URL válida
      var regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

      // Testa a string com a expressão regular
      return regex.test(url);
    },
  },
  data() {
    return {
      is_not_many: false,
      link_to_short: "",
      error_link: false,
      is_loading: true,
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.$route.params?.id) {
        return axios
          .get(`${url_base}/${this.$route.params?.id}`)
          .catch(() => {
            this.is_loading = false;
            this.$router.push("/");
          })
          .then((e) => {
            window.location.replace(`${e.data.url}`);
            return e;
          });
      } else {
        this.is_loading = false;
        this.$router.push("/");
      }
    });
  },
};
</script>

<style scoped>
#ismany-info {
  display: none;
  color: #555; /* Cor do texto informativo */
  font-style: italic; /* Estilo do texto informativo */
}

#ismany:checked + label + p {
  display: block;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.classic {
  position: absolute;

  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%);
}
</style>
