<template>
  <div class="">
    <!-- Top Navigation Menu -->
    <div class="topnav">
      <div id="myLinks">
        <a href="/">Curtinho</a>
        <a href="/contador">Contador</a>
        <a href="/termos">Termos de Serviço</a>
        <a href="/privacidade">Privacidade</a>
      </div>
      <a href="javascript:void(0);" class="icon" @click="showMenu"
        ><i class="fa fa-bars"></i>
      </a>
    </div>
  </div>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    msg: String,
  },
  methods: {
    showMenu() {
      var x = document.getElementById("myLinks");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#headerbox {
  background-color: #1b1b3a !important;
}

.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
}

.topnav #myLinks {
  display: none;
}

@media screen and (min-width: 800px) {
  .topnav #myLinks {
    display: block !important;
  }
  .icon {
    display: none !important;
  }

  .topnav {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.topnav a {
  float: left;
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a.icon {
  float: right;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}
</style>
